
document.addEventListener('keydown', function(e) {
    if (e.key === 'Escape'|| e.key == 'Esc') {
      document.querySelectorAll(".dropdown").forEach(function(dropdown) {
        dropdown.querySelectorAll(".dropdown-menu").forEach(function(el) {
          el.setAttribute("hidden", "");
        });
        dropdown.querySelectorAll(".dropdown-toggle").forEach(function(el) {
          el.setAttribute("aria-expanded", "false");
        });
      });
    }
}, true);

document.addEventListener('click', function (e) {
  const isInDropdown = e.target.closest(".dropdown")

  if (isInDropdown) return;

  hideAllDropdowns();
}, true);


function hideAllDropdowns () {
  document.querySelectorAll(".dropdown-menu").forEach(function(el) {
    el.setAttribute("hidden", "");
  });
  document.querySelectorAll(".dropdown-toggle").forEach(function(el) {
    el.setAttribute("aria-expanded", "false");
  });
}

hideAllDropdowns();

document.querySelectorAll(".dropdown-toggle").forEach(function(el) {
  el.addEventListener("click", e => {
    e.preventDefault();

    const expanded = el.getAttribute("aria-expanded");
    const menu = el.parentNode.querySelector(".dropdown-menu");

    if (expanded === "true") {
      el.setAttribute("aria-expanded", "false");
      menu.setAttribute("hidden", "");
    } else {
      hideAllDropdowns();
      el.setAttribute("aria-expanded", "true");
      menu.removeAttribute("hidden");
    }
  });
})
