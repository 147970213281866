
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}



let bot_noclick = true;


const meetingLinks = {
  "Higher education" : null,
  "Secondary education": null,
  "Vocational school": null,
  "Corporate": null,
}

const forms = document.querySelectorAll("form.ajax");
// const tokenEl = document.getElementById("g-recaptcha-token")
// const siteKeyEl = document.getElementById("site_key")

let bot_too_fast = true;

setTimeout(() => {
  bot_too_fast = false;
}, 20000);

forms.forEach(form => {
  const nameInput = form.querySelector("[name=name]")

  nameInput.addEventListener("input", event => {
    const nameValue = nameInput.value || "";

    const splitName = nameValue.trim().split(" ");
    const firstName = splitName.slice(0, -1).join(" ");
    const lastName = splitName.length > 1 ? splitName[splitName.length - 1] : "";
    form.querySelector("[name=first_name]").value = firstName;
    form.querySelector("[name=last_name]").value = lastName;
  });

  form.addEventListener("submit", async event => {
    event.preventDefault();
    event.stopPropagation();

    // clear all errors everywhere
    document.querySelectorAll(".is-invalid")
      .forEach(invalidEl => invalidEl.classList.remove("is-invalid"));

    form.querySelector("button").setAttribute("disabled", true);
    form.closest(".contact_form").classList.add("muted");
    form.closest(".contact_form").classList.add("wait");

    const url = form.getAttribute("action");
    const formName = form.getAttribute("name");
    const data = new FormData(form);

    const hutk = getCookie("hubspotutk");
    hutk && data.set("hutk", hutk);

    data.set("bot_too_fast", bot_too_fast);
    data.set("bot_noclick", bot_noclick);

    const response = await fetch(url, {
      method: 'POST',
      body: data
    });

    const jsonResponse = await response.json();

    if (jsonResponse.success) {
      window.dataLayer.push({
        event: "form_tracking",
        eventCategory: "leadform",
        eventAction: "submit",
        eventLabel: formName,
      });

      const successEl = form.closest(".container").querySelector(".form_success");
      const formWrapper = form.closest(".contact_form")

      formWrapper.classList.add("hidden");
      formWrapper.classList.remove("visible");

      successEl.classList.add("visible");

      const email = data.get("email");
      const name = data.get("name");
      const institution = data.get("institution");
      const academicLevel = data.get("level");
      const nameParts = name.split(" ");
      const firstName = nameParts.slice(0, -1).join(" ");
      const lastName = nameParts[nameParts.length - 1];

      const args = `firstName=${firstName}&lastName=${lastName}&email=${email}&institution=${institution}&academicLevel=${academicLevel}`;

      const hubspotData = {
        email,
        firstName,
        lastName,
      };

      window._hsq.push(["identify", hubspotData]);

      if (jsonResponse.redirect) {
        window.location.href = `${jsonResponse.redirect}?${args}`;
      }
    } else if (jsonResponse.errors) {
      let el;
      let els;
      for (name in jsonResponse.errors) {
        el = form.querySelector(`[name='${name}']`);
        if (el) el.classList.add("is-invalid");
        if (els) els.forEach(e => e.classList.add("is-invalid"));
      }

      form.closest(".contact_form").classList.remove("muted");
      form.closest(".contact_form").classList.remove("wait");
    }

    form.querySelector("button").removeAttribute("disabled");
  });
});
