
document.querySelectorAll("[data-click]").forEach(function(e) {
  e.addEventListener("click", () => {
    const event = e.getAttribute("data-click");
    window.plausible(event);
  });
})

document.getElementById("hms-login").addEventListener("click", function(event) {
    window.dataLayer.push({ event: "login_click" });
}, false);

window.addEventListener('CookiebotOnAccept', function (e) {
  if (Cookiebot.consent.marketing) {
    // hubspot chat
    !function(e,t){if(!document.getElementById(e)){var c=document.createElement("script");c.src="//js.hs-analytics.net/analytics/1584702600000/1713649.js",c.type="text/javascript",c.id=e;var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(c,n)}}("hs-analytics");
  }

  // if (Cookiebot.consent.statistics) {
  //   // Tag manager
  //   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.nonce="{{ CSP_NONCE }}";j.src=
  //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','GTM-WPG2PD4');
  // }
}, false);

